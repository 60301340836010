import React, { FC } from 'react';
import Layout from '../../../../app/Layout';
import LoginForm from './Form';

const LoginPage: FC = () => (
  <Layout>
    <LoginForm />
  </Layout>
)

export default LoginPage