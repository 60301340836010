import { Button, Result } from 'antd'
import React, { FC } from 'react'
import Layout from '../app/Layout'
import Page from '../app/Page'

type NotFoundPageProps = {
  onReturn: () => void
}

const NotFoundPage: FC<NotFoundPageProps> = ({ onReturn }) => (
  <Layout>
    <Page>
      <Result
        style={{ padding: 0 }}
        status='404'
        title='404'
        subTitle={`Désolé, la page à laquelle vous essayez d'accéder n'existe pas`}
        extra={
          <Button
            type='primary'
            onClick={onReturn}
          >
            Revenir à l'acceuil
          </Button>
        }
      />
    </Page>
  </Layout>
)

export default NotFoundPage