import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useRouting from '../../../../hooks/useRouting'
import { logout } from '../../slices/logout'

const Logout: FC = () => {
  const dispatch = useDispatch()
  const { goToLogin } = useRouting()

  useEffect(() => {
    dispatch(logout())
    goToLogin()
  }, [goToLogin, dispatch])

  return null
}

export default Logout