import { Button, Form, Input } from 'antd';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from '../../../../app/Page';
import config from '../../../../config/config';
import useRouting from '../../../../hooks/useRouting';
import { UserRole } from '../../../users/types';
import { login, useLoginSelector } from '../../slices/login';

const LoginForm: FC = () => {
  const dispatch = useDispatch()
  const { goToDashboard, goToManager } = useRouting()

  const [username, setUsername] = useState(config.login.username || '')
  const [password, setPassword] = useState(config.login.password || '')
  const [errorMessage, setErrorMessage] = useState('')

  const { role, url, date, success, loading, error } = useLoginSelector()

  useEffect(() => {
    if (success && role) {
      if (role === UserRole.Standard && url && date) {
        axios.get(url, {
          headers: {
            'LTS-DATE': date,
            'Accept': 'text/html',
          },
        })
          .then(({ data }) => {
            const doc = document.open("text/html", "replace")
            doc.write(data)
            doc.close()
          })
          .catch(err => {
            setErrorMessage('Conflit de sessions, merci de vous déconnecter de votre session LTS active')
          })
      } else if (role === UserRole.Customer) {
        goToDashboard()
      } else if (role === UserRole.Admin) {
        goToManager()
      }
    } else if (error) {
      const message = errorMessageMap[error.statusCode] || `Erreur interne, merci de réessayer plus tard`
      setErrorMessage(message)
    }
  }, [success, error, role, url, date, goToDashboard, goToManager])

  const handleFinish = async () => {
    setErrorMessage('')
    try {
      dispatch(login(username, password))
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <Page>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          username, password
        }}
        onFinish={handleFinish}
      >
        <Form.Item
          name="username"
          validateTrigger='onBlur'
          rules={[
            {
              required: true,
              message: `Merci de renseigner votre nom d'utilisateur`,
            },
          ]}
        >
          <Input
            prefix={<i className="ri-user-line" />}
            placeholder={`Nom d'utilisateur`}
            size='large'
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Merci de renseigner votre mot de passe',
            },
          ]}
        >
          <Input
            prefix={<i className="ri-lock-line" />}
            type="password"
            placeholder="Mot de passe"
            size='large'
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </Form.Item>

        <Form.Item
          style={{ margin: 0 }}
          validateStatus={errorMessage ? 'error' : 'validating'}
          help={errorMessage}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            Se connecter
          </Button>
        </Form.Item>
      </Form>
    </Page>
  );
};

export default LoginForm

const errorMessageMap: { [code: number]: string } = {
  401: `Nom d'utilisateur ou mot de passe incorrect`,
}