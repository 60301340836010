import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { initialRemoteState, start, error, reset } from '../../../redux/remoteStateWithSuccess';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ApiError } from '../../../types/api';
import { RemoteStateWithSuccess } from '../../../types/redux';
import { UserRole } from '../../users/types';
import AuthApi from '../api';

export type LoginState = RemoteStateWithSuccess & {
  role: UserRole | null
  url: string | null
  date: string | null
}

const initialState: LoginState = {
  role: null,
  url: null,
  date: null,
  ...initialRemoteState
}

const {
  reducer,
  actions,
} = createSlice({
  name: 'login',
  initialState,
  reducers: {
    start, error, reset,
    success: (state: LoginState, action: PayloadAction<{ role: UserRole, url?: string, date: string }>): void => {
      state.role = action.payload.role
      state.url = action.payload.url || null
      state.date = action.payload.date
      state.success = true
      state.loading = false
      state.error = null
    },
  }
})

export default reducer

export const login = (username: string, password: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(actions.start())
      const result = await AuthApi.login(username, password)
      dispatch(actions.success(result))
    } catch (err) {
      dispatch(actions.error(err as ApiError))
    }
    dispatch(actions.reset())
  }

export const useLoginSelector = (): LoginState =>
  useSelector((state: RootState) => state.auth.login)