import { useSelector } from 'react-redux'
import { makeSlice } from '../../../redux/remoteStateWithSuccess'
import { RootState } from '../../../redux/rootReducer'
import { AppThunk } from '../../../redux/store'
import { NewUser } from '../../users/types'
import AuthApi from '../api'
import { whoAmI } from './whoAmI'

const { reducer, actions } = makeSlice('register')

export type RegisterState = ReturnType<typeof reducer>

export default reducer

const { start, success, error, reset } = actions

export const register = (user: NewUser): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      await AuthApi.register(user)
      dispatch(whoAmI())
      dispatch(success())
    } catch (err) {
      dispatch(error(err))
    }
    dispatch(reset())
  }

export const useRegisterSelector = () =>
  useSelector((state: RootState) => state.auth.register)