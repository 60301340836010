import { combineReducers } from 'redux';
import authReducer from '../features/auth/reducer';
import { serversReducer } from '../features/servers';
import { terminalsReducer } from '../features/terminals';
import { filesReducer } from '../features/files';

export const rootReducer = combineReducers({
  auth: authReducer,
  terminals: terminalsReducer,
  servers: serversReducer,
  files: filesReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer