import { Row, Space, Typography } from 'antd'
import React, { FC } from 'react'
import Logo from './Logo'

const { Title } = Typography

type PageProps = {
  title?: string
  extra?: JSX.Element
}

const Page: FC<PageProps> = ({ children, title, extra: Extra }) => {
  return (
    <Space
      size='middle'
      style={{ backgroundColor: 'white', padding: '40px', width: '100%' }}
      direction="vertical"
    >
      <Row justify='space-between'>
        {title ? <Title level={3}>{title}</Title> : <Logo />}
        {Extra}
      </Row>
      {children}
    </Space>
  )
}

export default Page