import { Rule } from 'rc-field-form/lib/interface'

const password: Rule = () => ({
  validator(_: any, value: string) {
    if (value.length < 8) {
      return Promise.reject('Votre mot de passe doit faire plus de 8 caractères')
    }
    else if (!/[a-z]/.test(value)) {
      return Promise.reject('Votre mot de passe doit contenir au moins une minuscule')
    }
    else if (!/[A-Z]/.test(value)) {
      return Promise.reject('Votre mot de passe doit contenir au moins une majuscule')
    }
    else if (!/\d/.test(value)) {
      return Promise.reject('Votre mot de passe doit contenir au moins un chiffre')
    }
    return Promise.resolve()
  }
})

const siret: Rule = () => ({
  validator(_: any, value: string) {
    if (value.length !== 14) {
      return Promise.reject('erreur de taille')
    }
    else if (/[a-zA-Z]/.test(value)) {
      return Promise.reject('Le siret ne peut contenir de caractères alphabétiques')
    }
    return Promise.resolve()
  }
})

const required: Rule = {
  required: true,
  message: 'Ce champ est obligatoire',
}

const formRules = {
  password,
  siret,
  required,
}

export default formRules