import { Typography } from 'antd'
import { FC } from 'react'

const PasswordToolip: FC = () => (
  <Typography.Text style={{ color: 'white' }}>
    Votre mot de passe doit contenir :
    <ul>
      <li>au moins 8 caractères</li>
      <li>au moins une minuscule, une majuscule et un chiffre</li>
    </ul>
  </Typography.Text>
)

export default PasswordToolip