import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from '../routing/Router'
import './App.less'

const App: FC = () => {
  // const refreshSession = useRefreshSession()

  // useInterval(refreshSession, config.intervals.refreshSession)
  // useEffect(refreshSession, [refreshSession])

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  )
}

export default App