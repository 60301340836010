import { useSelector } from 'react-redux';
import { makeGetSlice } from 'redux-helper';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { Terminal } from '../types';

const {
  reducer: getTerminalsReducer,
  actions: { start, success, error, reset },
} = makeGetSlice<Terminal>('getTerminals')

export type GetTerminalsState = ReturnType<typeof getTerminalsReducer>

export default getTerminalsReducer

export const getTerminals = (): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      // const terminals: Terminal[] = await terminalsAPI.getTerminals()
      const terminals: Terminal[] = []
      dispatch(success(terminals))
    } catch (err) {
      dispatch(error(err))
    }
    dispatch(reset())
  }

export const useGetTerminalsSelector = () =>
  useSelector((state: RootState) => state.terminals.get)