import { useSelector } from 'react-redux';
import { makeGetSlice } from 'redux-helper';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { Server } from '../types';

const {
  reducer: getServersReducer,
  actions: { start, success, error, reset },
} = makeGetSlice<Server>('getServers')

export type GetServersState = ReturnType<typeof getServersReducer>

export default getServersReducer

export const getServers = (): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      // const servers: Server[] = await serversAPI.getServers(token)
      const servers: Server[] = []
      dispatch(success(servers))
    } catch (err) {
      dispatch(error(err))
    }
    dispatch(reset())
  }

export const useGetServersSelector = () =>
  useSelector((state: RootState) => state.servers.get)