import { combineReducers } from 'redux';
import loginReducer from './slices/login';
import whoAmIReducer from './slices/whoAmI';
import logoutReducer from './slices/logout';
import sessionReducer from './slices/session';
import registerReducer from './slices/register';
import setPasswordReducer from './slices/setPassword';

const authReducer = combineReducers({
  login: loginReducer,
  whoAmI: whoAmIReducer,
  register: registerReducer,
  setPassword: setPasswordReducer,
  logout: logoutReducer,
  session: sessionReducer,
})

export type AuthState = ReturnType<typeof authReducer>

export default authReducer
