import { useSelector } from 'react-redux'
import { makeSlice } from '../../../redux/remoteStateWithSuccess'
import { RootState } from '../../../redux/rootReducer'
import { AppThunk } from '../../../redux/store'
import { User } from '../../users/types'
import AuthApi from '../api'
import { setSession } from './session'

const { reducer, actions } = makeSlice('whoAmI')

export type WhoAmIState = ReturnType<typeof reducer>

export default reducer

const { start, success, error, reset } = actions

export const whoAmI = (): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      const user: User | null = await AuthApi.whoAmI()
      if (!user) {
        throw Error('User not found')
      }
      dispatch(setSession(user))
      dispatch(success())
    } catch (err) {
      dispatch(error(err))
    }
    dispatch(reset())
  }

export const useWhoAmISelector = () =>
  useSelector((state: RootState) => state.auth.whoAmI)