import { useSelector } from 'react-redux'
import { makeSlice } from '../../../redux/remoteStateWithSuccess'
import { RootState } from '../../../redux/rootReducer'
import { AppThunk } from '../../../redux/store'
import { Id } from '../../../types/common'

const { reducer, actions } = makeSlice('setPassword')

export type SetPasswordState = ReturnType<typeof reducer>

export default reducer

const { start, success, error, reset } = actions

export const setPassword = (user: Id, password: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      // await authAPI.setPassword(user, password)
      dispatch(success())
    } catch (err) {
      dispatch(error(err))
    }
    dispatch(reset())
  }

export const useSetPasswordSelector = () =>
  useSelector((state: RootState) => state.auth.setPassword)