import { AndroidFilled, AppleFilled, WindowsFilled } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import Layout from '../../../app/Layout';
import Page from '../../../app/Page';
import config from '../../../config/config';

export enum OS {
  Windows = 'Windows',
  MacOS = 'MacOS',
  Android = 'Android',
}

const url = `${config.serverBaseUrl}/download`

const DownloadsPage: FC = () => {
  return (
    <Layout>
      <Page>
        <Typography.Title level={5}>Téléchargements du client Sologic</Typography.Title>
        <Row justify='space-between' style={{ width: '100%' }} gutter={[8, 8]}>
          <Col span={8}>
            <DownloadButton icon={WindowsFilled} os={OS.Windows} />
          </Col>
          <Col span={8}>
            <DownloadButton icon={AppleFilled} os={OS.MacOS} />
          </Col>
          <Col span={8}>
            <DownloadButton icon={AndroidFilled} os={OS.Android} />
          </Col>
        </Row>
        <Typography.Title level={5}>Téléchargement du fichier .iso de UseIt</Typography.Title>
        <Row justify='space-between' style={{ width: '100%' }} gutter={[8, 8]}>
          <Col span={8}>
            <Button
              size='large'
              style={{ width: '100%' }}
              href={`${url}/Linux`}
            >
              UseIt4 D9
            </Button>
          </Col>
        </Row>
      </Page>
    </Layout>
  )
}

type DownloadButtonProps = {
  icon: FC
  os: OS
}

const DownloadButton: FC<DownloadButtonProps> = ({ icon: Icon, os }) => (
  <Button
    size='large'
    style={{ width: '100%' }}
    icon={<Icon />}
    href={`${url}/${os}`}
  >
    {os}
  </Button>
)

export default DownloadsPage