import React, { FC } from 'react'

const Logo: FC = () => (
  <img
    style={{ width: 225, height: 67.89, paddingBottom: 16 }}
    alt='Sologic'
    src='/sologic-logo.png'
  />
)

export default Logo