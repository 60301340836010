import axios from 'axios'
import config from '../../config/config'
import { errorWrapper } from '../../utils/api'
import { User } from '../users'
import { NewUser, UserRole } from '../users/types'

const url = `${config.serverBaseUrl}`

class AuthApi {
  static register = (user: NewUser) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/auth/register`, user)
      return data
    }
  )

  static login = (username: string, password: string) => errorWrapper(
    async (): Promise<{ role: UserRole, url?: string, date: string }> => {
      const { data } = await axios.post(`${url}/auth/login`, { username, password })
      return data
    }
  )

  static logout = () => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/auth/logout`)
      return data
    }
  )

  static whoAmI = () => errorWrapper(
    async (): Promise<User> => {
      const { data } = await axios.get(
        `${url}/core/whoami`
      )
      return data
    }
  )
}

export default AuthApi