import { useHistory } from 'react-router-dom'

const useRouting = () => {
  const { push, goBack } = useHistory()

  return {
    goToLogin: () => push('/'),
    goToRegistrationStep: (step: string) => push(`/registration/${step}`),
    goToSettings: () => push('/settings'),
    goToImport: () => push('/import'),
    goToForgot: () => push('/forgot'),

    goToManager: () => push('/manager'),
    goToDashboard: () => push('/dashboard'),
    goToTicketing: () => push('/ticketing'),
    
    goBack
  }
}

export default useRouting