import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiError } from '../types/api'
import { RemoteState, RemoteStateWithSuccess } from './../types/redux'

export const initRemoteState = (): RemoteState => ({
  loading: false,
  error: null
})

export const initRemoteStateWithSuccess = (): RemoteStateWithSuccess => ({
  success: false,
  ...initRemoteState()
})

// TODO: export it from redux-helper
export const makeSlice = (name: string) => createSlice({
  name,
  initialState: initRemoteStateWithSuccess(),
  reducers: { start, success, error, reset }
})

export const initialRemoteState: RemoteStateWithSuccess = {
  success: false,
  loading: false,
  error: null,
}

export const start = <T extends RemoteStateWithSuccess>(state: T): void => {
  state.success = false
  state.loading = true
  state.error = null
}

export const success = <T extends RemoteStateWithSuccess>(state: T): void => {
  state.success = true
  state.loading = false
  state.error = null
}

export const error = <T extends RemoteStateWithSuccess>(
  state: T, action: PayloadAction<ApiError>
): void => {
  state.success = false
  state.loading = false
  state.error = action.payload
}

export const reset = <T extends RemoteStateWithSuccess>(state: T): void => {
  state.success = false
  state.loading = false
  state.error = null
}