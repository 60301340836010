import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/rootReducer'
import { Session } from '../../../types/auth'

export type SessionState = {
  session: Session | null
}

const initialState: SessionState = {
  session: null
}

const { reducer, actions } = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession(state: SessionState, action: PayloadAction<Session>) {
      state.session = action.payload
    },
    closeSession(state: SessionState) {
      state.session = null
    }
  }
})

export default reducer

export const { setSession, closeSession } = actions

export const useSessionSelector = () =>
  useSelector((state: RootState) => state.auth.session)