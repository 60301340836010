import { useSelector } from 'react-redux';
import { makeAsyncStateSlice } from 'redux-helper';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';

const {
  reducer: importFilesReducer,
  actions: { start, success, error, reset },
} = makeAsyncStateSlice('importFiles')

export type ImportFilesState = ReturnType<typeof importFilesReducer>

export default importFilesReducer

export const importFiles = (
  useitUsername: string, serverHostname: string, fileList: any[]
): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      // await filesAPI.importFiles(useitUsername, serverHostname, fileList)
      dispatch(success())
    } catch (err) {
      dispatch(error(err))
    }
    dispatch(reset())
  }

export const useImportFilesSelector = () =>
  useSelector((state: RootState) => state.files.import)