import { Id } from '../../types/common'

export enum UserRole {
  Admin = 'Admin',
  Customer = 'Customer',
  Standard = 'Standard'
}

export enum UserApplication {
  Ticketing = 'Ticketing',
  Payroll = 'Payroll',
  Customers = 'Customers',
  Import = 'Import',
}

export type User = {
  id: Id
  firstname: string
  lastname: string
  username: string
  role: UserRole
  applications: UserApplication[]
}

export type NewUser = {
  firstname: string
  lastname: string
  username: string
  phoneNumber: string
  password: string
}