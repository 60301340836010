import { Typography } from 'antd';
import React, { FC } from 'react';
import Layout from '../../../../../app/Layout';
import Page from '../../../../../app/Page';
import SetPasswordForm from './Form';

const { Text } = Typography

const SetPasswordPage: FC = () => (
  <Layout>
    <Page>
      <Text>Bienvenue sur Sologic.fr</Text>
      <Text>Pour commencer, créez un mot de passe pour votre compte</Text>
      <SetPasswordForm />
    </Page>
  </Layout>
)

export default SetPasswordPage