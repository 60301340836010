const env: any = process.env

const config = {
  serverBaseUrl: env.REACT_APP_SERVER_URL,
  managerBaseUrl: env.REACT_APP_MANAGER_URL,
  dashboardBaseUrl: env.REACT_APP_DASHBOARD_URL,
  ticketingBaseUrl: env.REACT_APP_TICKETING_URL,
  intervals: {
    refreshSession: 10 * 1000,
    useCheckSession: 5 * 1000,
  },
  login: {
    username: env.REACT_APP_LOGIN_EMAIL,
    password: env.REACT_APP_LOGIN_PASSWORD,
    // username: 'l@g.co',
    // password: '321',
  },
  logs: {
    debug: env.REACT_APP_DEBUG
  },
  layout: {
    centeredLayout: {
      breakpoints: {
        'medium': { xs: 22, sm: 22, md: 20, lg: 18, xl: 16, xxl: 14 },
        'large': { xs: 22, sm: 22, md: 20, lg: 20, xl: 20, xxl: 20 },
      }
    }
  }
}

export default config