import { ApiError } from '../types/api'

export const errorWrapper = async (fn: () => any) => {
  try {
    return await fn()
  } catch (err) {
    const error: ApiError = {
      message: err.response.data,
      statusCode: err.response.status,
    }
    throw error
  }
}