import { LockOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Tooltip } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import PasswordToolip from '../../../../../common/PasswordTooltip'
import useRouting from '../../../../../hooks/useRouting'
import { Id } from '../../../../../types/common'
import formRules from '../../../../../utils/rules'
import { setPassword as setPasswordAction, useSetPasswordSelector } from '../../../slices/setPassword'

const PasswordForm: FC = () => {
  const { user } = useParams() as { user: Id }
  const dispatch = useDispatch()
  const { goToLogin } = useRouting()

  const [password, setPassword] = useState<string>()
  const [confirm, setConfirm] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const { success, loading, error } = useSetPasswordSelector()

  useEffect(() => {
    if (success) {
      message.success('Mot de passe configuré, vous pouvez maintenant vous connecter')
      goToLogin()
    } else if (error) {
      setErrorMessage('Echec de la configuration du mot de passe')
    }
  }, [success, error, goToLogin])

  const handleFinish = async () => {
    if (password) {
      dispatch(setPasswordAction(user, password))
    }
  }

  return (
    <Form onFinish={handleFinish}>
      <Tooltip
        trigger={['focus']}
        title={<PasswordToolip />}
        placement="right"
        overlayClassName="numeric-input"
      >
        <Form.Item
          name="password"
          rules={[formRules.password]}
          hasFeedback
        >

          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Mot de passe"
            size='large'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Item>
      </Tooltip>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Merci de confirmer votre mot de passe',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject('Les mots de passes ne correspondent pas')
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Mot de passe (confirmation)"
          size='large'
          value={confirm}
          onChange={e => setConfirm(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={errorMessage ? 'error' : 'validating'}
        help={errorMessage}
      >
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading}
        >
          Confirmer
          </Button>
      </Form.Item>
    </Form>
  )
}

export default PasswordForm



