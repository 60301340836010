import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../common/NotFoundPage'
import config from '../config/config'
import { SetPasswordPage } from '../features/auth/components'
import LoginPage from '../features/auth/components/login/Page'
import Logout from '../features/auth/components/logout/Logout'
import DownloadsPage from '../features/downloads/components/Page'
import useRouting from '../hooks/useRouting'

const Router: FC = () => {
  const { goToLogin } = useRouting()

  const goToManager = () => {
    window.location.href = `${config.managerBaseUrl}`
    return null
  }

  const goToDashboard = () => {
    window.location.href = `${config.dashboardBaseUrl}`
    return null
  }

  return (
    <Switch>
      <Route path='/set-password/:user' component={SetPasswordPage} />
      <Route exact path='/' component={LoginPage} />
      <Route path='/logout' component={Logout} />
      <Route path='/download' component={DownloadsPage} />
      <Route path='/télécharger' component={DownloadsPage} />
      <Route path='/manager' component={goToManager} />
      <Route path='/dashboard' component={goToDashboard} />
      <Route path='*'>
        <NotFoundPage onReturn={goToLogin} />
      </Route>
    </Switch>
  )
}

export default Router